import { useHistory, useLocation } from "react-router-dom";
import React, {useState} from 'react'; 
import { FormattedMessage } from "react-intl";
import {Navigation} from 'react-minimal-side-navigation';
import UserService from '../services/UserService';

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

const Menu = () => {
    const history = useHistory();
    const location = useLocation();
    const [hideNotificacion, setHideNotificacion] = useState(true);

    if(hideNotificacion != "" && UserService.getRealm() == "backoffice-dev"){
        console.log(UserService.getRealm());
        setHideNotificacion(false);
    }

    return(

        <React.Fragment>    
            <div className="logo">
                <div className="img-logo"></div>
                <div className="text-logo">
                    
                </div>
            </div>
            <div className="absolute bottom-0 w-full my-8 linea">
                <Navigation
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                    history.push(itemId);
                    }}
                    items={[
                    {
                        title: <FormattedMessage id="menu.dashboard" defaultMessage="DashBoard"/>,
                        itemId: "/",
                        elemBefore: () =>  <i className="th icon" />
                    }
                    ]}
                />
            </div>
            <div className="absolute bottom-0 w-full my-8 linea">
                <Navigation
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                    history.push(itemId);
                    }}
                    items={[
                    {
                        title: <FormattedMessage id="menu.configureSeccion" defaultMessage="Configurar Secciones"/>,
                        itemId: "/configureHome",
                        elemBefore: () =>  <i className="tasks icon" />
                    }
                    ]}
                />
            </div>      

            {/* <div className="absolute bottom-0 w-full my-8 linea">
                <Navigation
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                    history.push(itemId);
                    }}

                    items={[
                        {
                        title: <FormattedMessage id="menu.administracion" defaultMessage="Administración"/>,
                        itemId: false,
                        elemBefore: () => <i className="key  icon" />,
                            subNav: [
                                {
                                    title: <FormattedMessage id="menu.usuarios" defaultMessage="Usuarios"/>,
                                    itemId: "/users",
                                    elemBefore: () =>  <i className="users icon" />
                                },
                                {
                                    title: <FormattedMessage id="menu.grupos" defaultMessage="Grupos"/>,
                                    itemId: "/groups",
                                    elemBefore: () =>  <i className="address card outline icon" />
                                },
                                {
                                    title: <FormattedMessage id="menu.parametros" defaultMessage="Parámetros"/>,
                                    itemId: "/parameters",
                                    elemBefore: () =>  <i className="cogs icon" />
                                },
                                {
                                    title: <FormattedMessage id="menu.textos.app" defaultMessage="Textos App"/>,
                                    itemId: "/appTexts",
                                    elemBefore: () =>  <i className="language icon" />
                                },
                            ]
                        }
                        ]}
                />
            </div> */}

            <div className="absolute bottom-0 w-full my-8 linea">
                <Navigation
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                    history.push(itemId);
                    }}
                    items={[
                    {
                        title: <FormattedMessage id="menu.notificaciones" defaultMessage="Notificaciones"/>,                        
                        itemId: false,
                        elemBefore: () =>  <i className="mobile alternate icon" />,
                        subNav: [
                            {
                                title: <FormattedMessage id="menu.notificaciones.enviar" defaultMessage="Enviar"/>,
                                itemId: "/notificationSending",
                                elemBefore: () =>  <i className="paper plane outline icon" />
                            },
                            // {
                            //     title: <FormattedMessage id="menu.notificaciones.registros" defaultMessage="Registros"/>,
                            //     itemId: "/notificationLogs",
                            //     elemBefore: () =>  <i className="hdd outline icon" />
                            // },
                            // {
                            //     title: <FormattedMessage id="menu.notificaciones.estadisticas" defaultMessage="Estadísticas"/>,
                            //     itemId: "/notificationReports",
                            //     elemBefore: () =>  <i className="chart bar icon" />
                            // },
                        ]
                    }
                    ]}
                />
            </div>
            <div className="absolute bottom-0 w-full my-8 linea">
                <Navigation
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                        history.push(itemId);
                    }}
                    items={[
                    {
                        title: <FormattedMessage id="menu.servicios" defaultMessage="Servicios"/>,
                        elemBefore: () =>  <i className="certificate icon" />,
                        itemId : false,
                        
                        subNav: [
                        {
                            title: <FormattedMessage id="menu.aldia.noticias" defaultMessage="Noticias"/>,
                            itemId: "/news",
                            elemBefore: () =>  <i className="newspaper outline icon" />,
                        },
                        {
                            title: <FormattedMessage id="menu.aldia.boletin" defaultMessage="Boletín"/>,
                            itemId: "/bulletin",
                            elemBefore: () =>  <i className="file alternate outline icon" />,
                        },
                        {
                            title: <FormattedMessage id="menu.destacados" defaultMessage="Destacados"/>,
                            itemId: "/featured",
                            elemBefore: () =>  <i className="bullhorn icon" />
                        },
                        {
                            title: <FormattedMessage id="menu.vivaBizkaia.playas" defaultMessage="Playas"/>,
                            itemId: "/beaches",
                            elemBefore: () =>  <i className="flag icon" />
                        },
                        {
                            title: <FormattedMessage id="menu.vivaBizkaia.piscinas" defaultMessage="Piscinas"/>,
                            itemId: "/swimming-pools",
                            elemBefore: () =>  <i className="life ring icon" />
                        },
                        {
                            title: <FormattedMessage id="menu.vivaBizkaia.planesyrutas" defaultMessage="Planes y rutas"/>,
                            itemId: "/routesandplans",
                            elemBefore: () =>  <i className="map signs icon" />
                        },
                        {
                            title: <FormattedMessage id="menu.vivaBizkaia.biblioteca" defaultMessage="Biblioteca"/>,
                            itemId: "/library",
                            elemBefore: () =>  <i className="book icon" />
                        }
                        ]
                    }
                    ]}
                />
            </div>


            <div className="absolute bottom-0 w-full my-8 linea">
                <Navigation
                    activeItemId={location.pathname}
                    onSelect={({ itemId }) => {
                    history.push(itemId);
                    }}
                    items={[
                    {
                        title: <FormattedMessage id="menu.mediaManager" defaultMessage="Media Manager"/>,
                        itemId: "/files",
                        elemBefore: () => <i className="images outline icon" />,
                    }
                    ]}
                />
            </div>

            <div className={`${hideNotificacion ? "hide-EsComponent" : ""}`} >
                <div className="absolute bottom-0 w-full my-8 linea">
                
                    <Navigation
                        activeItemId={location.pathname}
                        onSelect={({ itemId }) => {
                        history.push(itemId);
                        }}
                        items={[
                        {
                            title: <FormattedMessage id="menu.notificacionesPush" defaultMessage="Notificaciones Push"/>,
                            itemId: "/push-notification",
                            elemBefore: () => <i className="bell outline icon"  />,
                        }
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Menu
