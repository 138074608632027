import { FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Alert from '@mui/material/Alert';
import { getServices, filterDevices, sendCustomNotification } from '../services/NotificationService';
import Collapse from '@mui/material/Collapse';

const NotificationSending = () => {

    const FIELDS = {
        SELECT_DISPOSITIVO: "dispositivos",
        SELECT_SERVICIOS: "servicios",
        CHECKBOX_ULTIMA_CONEXION: "ultimaConexion",
        CHECKBOX_DISPOSITIVOS_REGISTRADOS: "dispositivosRegistrados",
        INPUT_DNI: "dni",
        INPUT_FECHA_ULTIMA_CONEXION: "fechaUltimaConexion",
        INPUT_NUMERO_REGISTROS: "numeroRegistros",
        INPUT_TITULO: "titulo",
        INPUT_DESCRIPCION: "descripcion"
    };

    const DEVICE_FILTER_VALUES = {
        TODOS: "Todos",
        ANDROID: "Android",
        IOS: "IOS",
        UNICO: "Unico"
    }

    const MAX_LENGTH = {
        [FIELDS.INPUT_TITULO]: 50,
        [FIELDS.INPUT_DESCRIPCION]: 150
    }

    const getFilterInitialState = () => {
        return {
            [FIELDS.SELECT_DISPOSITIVO]: "",
            [FIELDS.SELECT_SERVICIOS]: "",
            [FIELDS.CHECKBOX_ULTIMA_CONEXION]: false,
            [FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS]: false,
            [FIELDS.INPUT_DNI]: "",
            [FIELDS.INPUT_FECHA_ULTIMA_CONEXION]: "",
            [FIELDS.INPUT_NUMERO_REGISTROS]: "",
        };
    };

    const getFilterValidationInitialState = () =>{
        return {
            [FIELDS.SELECT_DISPOSITIVO]: true,
            [FIELDS.SELECT_SERVICIOS]: true,
            [FIELDS.INPUT_DNI]: true,
            [FIELDS.INPUT_FECHA_ULTIMA_CONEXION]: true,
            [FIELDS.INPUT_NUMERO_REGISTROS]: true,
        };        
    }

    const getNotificationInitialState = () => {
        return {
            [FIELDS.INPUT_TITULO]: "",
            [FIELDS.INPUT_DESCRIPCION]: "",
        };
    };

    const getNotificationValidationInitialState = () =>{
        return {
            [FIELDS.INPUT_TITULO]: true,
            [FIELDS.INPUT_DESCRIPCION]: true,
        };        
    }

    const getIconsInitialState = () => {
        return {
            android: '',
            ios: '',
            mobile: '',
        };
    };

    const getIcons = (selectedDevice) => {
        const newIcons = getIconsInitialState();
        switch (selectedDevice) {
            case DEVICE_FILTER_VALUES.ANDROID:
                newIcons.android = 'ui icon-android icon';
                break;
            case DEVICE_FILTER_VALUES.IOS:
                newIcons.ios = 'ui icon-ios icon';
                break;
            case DEVICE_FILTER_VALUES.TODOS:
                newIcons.android = 'ui icon-android icon';
                newIcons.ios = 'ui icon-ios icon';
                break;
            case DEVICE_FILTER_VALUES.UNICO:
                newIcons.mobile = 'ui icon-phone icon';
                break;
            default:
                break;
        }
        return newIcons;
    };

    useEffect(() => {
        const loadServicesSelect = () => {
            getServices()
                .then(result => {
                    console.log(result);
                    let options = [];
                    options.push(...result.data.map((service, index) => (
                        <option key={index + 1} value={service}>{service}</option>
                    )));
                    setServiceOptions(options);
                }).catch(error => {
                    console.log(error);
                    let errorMsg = "Error al obtener el listado de servicios: ";
                    if(error.response.status == 0){
                        errorMsg += error.code + " " + error.message;
                    }else{
                        errorMsg += error.response.status + " " + error.response.statusText;
                    }

                    setErrorMessage(errorMsg);
                    setShowErrorMessage(true);
                });
        };

        loadServicesSelect();
    }, []); 

    const intl = useIntl();
    const [serviceOptions, setServiceOptions] = useState([]);
    const [filtro, setFiltro] = useState(getFilterInitialState());
    const [notification, setNotification] = useState(getNotificationInitialState());
    const [icons, setIcons] = useState(getIconsInitialState());
    const [showDniField, setShowDniField] = useState(false);
    const [showSuscribeService, setShowSuscribeService] = useState(false);    
    const [showLastConection, setShowLastConection] = useState(false);
    const [showNumberOfDevicesFound, setShowNumberOfDevicesFound] = useState(false);
    const [numberOfDevicesFound, setNumberOfDevicesFound] = useState(0);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [filterValidation, setFilterValidation] = useState(getFilterValidationInitialState());
    const [notificationValidation, setNotificationValidation] = useState(getNotificationValidationInitialState());
    const [showFilterCheckbox, setShowFilterCheckbox] = useState(false);
  

    const handleFilterInputChange = (event) => {
        const { name, value, type, checked} = event.target;
        console.log("%s, %s, %s, %s",name, value, type, checked);
        
        let newValue = type === "checkbox" ? checked : value;

        setShowNumberOfDevicesFound(false);
        setNumberOfDevicesFound(0);
        setFilterInputValue(name, newValue);
        setFilterValidationValue(name, newValue);
        setNotificationValidation(getNotificationValidationInitialState());
    };

    const setFilterInputValue = (name, value) => {
        console.log("setFilterInputValue(%s,%s)",name,value);
        let updatedFiltro = { ...filtro};

        switch (name) {
            case FIELDS.SELECT_DISPOSITIVO:
                // if(value == ""){
                //     resetFilterForm();
                //     return;
                // } else if(value == DEVICE_FILTER_VALUES.UNICO){
                //     setShowDniField(true);
                //     setShowFilterCheckbox(false);
                // } else{
                //     setShowDniField(false);
                //     setShowFilterCheckbox(true);
                //     updatedFiltro = { ...updatedFiltro,[FIELDS.INPUT_DNI]: ""};
                // }
                // setIcons(getIcons(value));
                updatedFiltro = getFilterInitialState();
                setFilterValidation(getFilterValidationInitialState());
                setIcons(getIcons(value));
                setShowLastConection(false);
                setShowSuscribeService(false);
                setShowDniField(false);
                setShowFilterCheckbox(false);
                if(value == DEVICE_FILTER_VALUES.UNICO){
                    setShowDniField(true);
                } else if(value !== ""){
                    setShowFilterCheckbox(true);
                }
                break;
            case FIELDS.CHECKBOX_ULTIMA_CONEXION:
                setShowLastConection(value);
                if(!value){
                    updatedFiltro = { ...updatedFiltro,[FIELDS.INPUT_FECHA_ULTIMA_CONEXION]: ""};
                }                
                break;
            case FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS:
                setShowSuscribeService(value);
                if(!value){
                    updatedFiltro = {
                        ...updatedFiltro,
                        [FIELDS.INPUT_NUMERO_REGISTROS]: "",
                        [FIELDS.SELECT_SERVICIOS]: "",
                    };
                }
                break;
            case FIELDS.INPUT_NUMERO_REGISTROS:
                //Validación introducir números del 1 al 99
                if (!/^[1-9][0-9]?$/.test(value) && value !== "") {
                    return;
                }
                break;
            case FIELDS.INPUT_DNI:
                // Si el nuevo valor es una letra, se tranforma a mayúscula
                if(/[a-z]/.test(value)){
                    value = value.toUpperCase();
                }

                // Se comprueba si nuevo valor es una letra
                if(/[A-Z]/.test(value)){
                    // Se comprueba si ya están los primeros 8 números del DNI
                    if(!/^[0-9]{8}/.test(value)){
                        // Si no es están los 8 primeros números no se permite introducir la letra
                        return;
                    }
                }

                // Se comprueba el formato
                if (!/^[0-9]{0,8}[TRWAGMYFPDXBNJZSQVHLCKET]{0,1}$/.test(value) && value !== "") {
                    return;
                }

                break;
            default:
                break;
          }

        updatedFiltro[name] = value;
        console.log(filtro);
        setFiltro(updatedFiltro);
        //setFiltro({...filtro, [name]: value});
    }

    const setFilterValidationValue = (name, value) => { 
        if(name == FIELDS.SELECT_DISPOSITIVO) {
            if(value !== ""){
                setFilterValidation(prev => ({ ...prev, [FIELDS.SELECT_DISPOSITIVO]: true }));
            }
            if(value !== DEVICE_FILTER_VALUES.UNICO){
                setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_DNI]: true }));
            }
        }
        if(name == FIELDS.INPUT_DNI && value !== "") {
            setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_DNI]: true }));
        }
        if(name == FIELDS.CHECKBOX_ULTIMA_CONEXION && !value){
            setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_ULTIMA_CONEXION]: true }));
        }
        if(name == FIELDS.INPUT_FECHA_ULTIMA_CONEXION && value !== ""){
            setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_ULTIMA_CONEXION]: true }));
        }
        if(name == FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS && !value){
            setFilterValidation(prev => ({ 
                ...prev, 
                [FIELDS.INPUT_NUMERO_REGISTROS]: true,
                [FIELDS.SELECT_SERVICIOS]: true, 
            }));
        }
        if(name == FIELDS.SELECT_SERVICIOS && value !== "") {
            setFilterValidation(prev => ({ ...prev, [FIELDS.SELECT_SERVICIOS]: true }));
        }
        if(name == FIELDS.INPUT_NUMERO_REGISTROS && value !== "") {
            setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_NUMERO_REGISTROS]: true }));
        }
    }

    const handleNotificationInputChange = (event) => {
        const { name, value} = event.target;
        setNotificationInputValue(name, value)
        setNotificationValidationValue(name, value);
    };

    const setNotificationInputValue = (name, value) => {
        if (value.length <= MAX_LENGTH[name]) {
            setNotification({...notification, [name]: value});
        }
    }

    const setNotificationValidationValue = (name, value) =>{
        if(name == FIELDS.INPUT_TITULO && value !== "") {
            setNotificationValidation(prev => ({ ...prev, [FIELDS.INPUT_TITULO]: true }));
        }
        if(name == FIELDS.INPUT_DESCRIPCION && value !== ""){
            setNotificationValidation(prev => ({ ...prev, [FIELDS.INPUT_DESCRIPCION]: true }));
        }
    }

    const onClickLimpiarFiltro = () => {
        resetFilterForm();
    }

    const resetFilterForm = () => {
        setFiltro(getFilterInitialState());
        setFilterValidation(getFilterValidationInitialState());
        setIcons(getIconsInitialState());
        setShowLastConection(false);
        setShowSuscribeService(false);
        setShowDniField(false);
        setShowNumberOfDevicesFound(false);
        setShowFilterCheckbox(false);
        setNumberOfDevicesFound(0);
    }

    useEffect(() => {
        console.log(filterValidation);
    }, [filterValidation]);
    

    const onClickFiltrar = () => {
        setFilterValidation(getFilterValidationInitialState());
    
        let isRequiredFieldsValidate = true;
    
        if (filtro[FIELDS.SELECT_DISPOSITIVO] === "") {
            setFilterValidation(prev => ({ ...prev, [FIELDS.SELECT_DISPOSITIVO]: false }));
            isRequiredFieldsValidate = false;
        }else if(filtro[FIELDS.SELECT_DISPOSITIVO] == DEVICE_FILTER_VALUES.UNICO){
            //if(filtro[FIELDS.INPUT_DNI] === "" || !/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/.test(filtro[FIELDS.INPUT_DNI])){
            if(filtro[FIELDS.INPUT_DNI] === "" || !validateDniFormat(filtro[FIELDS.INPUT_DNI])){
                setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_DNI]: false }));
                isRequiredFieldsValidate = false;
            }
        }
    
        if (filtro[FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS]) {
            if (filtro[FIELDS.INPUT_NUMERO_REGISTROS] === "") {
                setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_NUMERO_REGISTROS]: false }));
                isRequiredFieldsValidate = false;
            }
            if (filtro[FIELDS.SELECT_SERVICIOS] === "") {
                setFilterValidation(prev => ({ ...prev, [FIELDS.SELECT_SERVICIOS]: false }));
                isRequiredFieldsValidate = false;
            }
        }
    
        if (filtro[FIELDS.CHECKBOX_ULTIMA_CONEXION]) {
            if (filtro[FIELDS.INPUT_FECHA_ULTIMA_CONEXION] === "") {
                setFilterValidation(prev => ({ ...prev, [FIELDS.INPUT_FECHA_ULTIMA_CONEXION]: false }));
                isRequiredFieldsValidate = false;
            }
        }
    
        console.log(filterValidation);
    
        if (isRequiredFieldsValidate) {
            filterDevices(getFilterValue()).then(result => {
                console.log(result);
                setNumberOfDevicesFound(result.data);
                setShowNumberOfDevicesFound(true);
            }).catch(error => {
                console.log(error);
                let errorMsg = "Error al filtrar dispositivos: ";
                if(error.response.status == 0){
                    errorMsg += error.code + " " + error.message;
                }else{
                    errorMsg += error.response.status + " " + error.response.statusText;
                }

                setErrorMessage(errorMsg);
                setShowErrorMessage(true);
            });
        }
    };

    const validateDniFormat = (dni) => {
        const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
        const dniRegex = /^\d{8}[a-zA-Z]$/;

        let isValidFormat = dniRegex.test(dni);
    
        console.log( "isValidFormat: " + isValidFormat);

        if (isValidFormat) {
            let dniNumbers = dni.slice(0, -1);
            let dniLetter = dni.slice(-1).toUpperCase();
            let letterIndex = dniNumbers % 23;
            let calculatedLetter = letters.charAt(letterIndex);
        
            console.log( "dniLetter: " + dniLetter);
            console.log( "calculatedLetter: " + calculatedLetter);

            return dniLetter === calculatedLetter;
        }else{
            return false;
        }
    }


    const getFilterValue = () => {
        let filterValue = {
            [FIELDS.SELECT_DISPOSITIVO]: filtro[FIELDS.SELECT_DISPOSITIVO],
        };

        if(filtro[FIELDS.CHECKBOX_ULTIMA_CONEXION]){
            filterValue[FIELDS.INPUT_FECHA_ULTIMA_CONEXION] = filtro[FIELDS.INPUT_FECHA_ULTIMA_CONEXION];
        }

        if(filtro[FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS]){
            filterValue[FIELDS.SELECT_SERVICIOS] = filtro[FIELDS.SELECT_SERVICIOS];
            filterValue[FIELDS.INPUT_NUMERO_REGISTROS] = filtro[FIELDS.INPUT_NUMERO_REGISTROS];
        }

        if(filtro[FIELDS.SELECT_DISPOSITIVO] == DEVICE_FILTER_VALUES.UNICO){
            filterValue[FIELDS.INPUT_DNI] = filtro[FIELDS.INPUT_DNI];
        }
        
        console.log(filterValue);
        return filterValue;
    }

    const onClickEnviar = () => {
        setNotificationValidation(getNotificationValidationInitialState());
    
        let isRequiredFieldsValidate = true;
    
        if (notification[FIELDS.INPUT_TITULO] === "") {
            setNotificationValidation(prev => ({ ...prev, [FIELDS.INPUT_TITULO]: false }));
            isRequiredFieldsValidate = false;
        }
        if(notification[FIELDS.INPUT_DESCRIPCION] === ""){
            setNotificationValidation(prev => ({ ...prev, [FIELDS.INPUT_DESCRIPCION]: false }));
            isRequiredFieldsValidate = false;
        }

        if (isRequiredFieldsValidate) {
            
            let message = {
                title: notification[FIELDS.INPUT_TITULO],
                body: notification[FIELDS.INPUT_DESCRIPCION]
            }

            sendCustomNotification(getFilterValue(), message).then(result => {
                setSuccessMessage("Se ha enviado la notificación correctamente");
                setShowSuccessMessage(true);
                // Timer para ocultar el mensaje tras 5 segundos
                setTimeout (() => {
                    setShowSuccessMessage(false);
                }, 5000);
            }).catch(error => {
                console.log(error);
                let errorMsg = "Error al enviar la notificación: ";
                if(error.response.status == 0){
                    errorMsg += error.code + " " + error.message;
                }else{
                    errorMsg +=  error.response.status + " " + error.response.statusText;
                }
                setErrorMessage(errorMsg);
                setShowErrorMessage(true);
            });
        }

    }


    return (
        <>
            {/* Contenedor principal */}
            <div className='mainDiv'>

                <Collapse in={showErrorMessage}>
                    <Alert 
                        style={{marginLeft: 10, marginRight: 10, marginTop: 10, display: 'flex', alignItems: 'center'}} 
                        onClose={() => {setShowErrorMessage(false)}} 
                        severity="error"
                    >{errorMessage}
                    </Alert>
                </Collapse>

                <Collapse in={showSuccessMessage}>
                    <Alert 
                        style={{marginLeft: 10, marginRight: 10, marginTop: 10, display: 'flex', alignItems: 'center'}} 
                        onClose={() => {setShowSuccessMessage(false)}} 
                        severity="success"
                    >{successMessage}
                    </Alert>
                </Collapse>

                <label className="title title-flx"> 
                    <FormattedMessage 
                        id="notificationSending.title.filtrar" 
                        defaultMessage="Filtrar dispositivos"/>
                </label>  

                <div className='ui form input-group-container'>
                    <div className='input-group'>
                        <label className='label required' htmlFor={FIELDS.SELECT_DISPOSITIVO}>
                            <FormattedMessage 
                                id="notificationSending.label.dispositivo" 
                                defaultMessage="Dispositivo:"/>
                        </label>
                        <select 
                            id={FIELDS.SELECT_DISPOSITIVO}
                            name={FIELDS.SELECT_DISPOSITIVO}
                            value={filtro[FIELDS.SELECT_DISPOSITIVO]} 
                            onChange={handleFilterInputChange} 
                            style={{width:230, marginRight: 10}} 
                            className={(filterValidation[FIELDS.SELECT_DISPOSITIVO])?'':'required-field'}
                        >
                            <option value="">
                                {intl.formatMessage({ id: 'notificationSending.option.dispositivo.default' })}
                            </option>
                            <option value={DEVICE_FILTER_VALUES.TODOS}>
                                {intl.formatMessage({ id: 'notificationSending.option.dispositivo.todos' })}
                            </option>
                            <option value={DEVICE_FILTER_VALUES.ANDROID}>
                                {intl.formatMessage({ id: 'notificationSending.option.dispositivo.android' })}
                            </option>
                            <option value={DEVICE_FILTER_VALUES.IOS}>
                                {intl.formatMessage({ id: 'notificationSending.option.dispositivo.ios' })}
                            </option>
                            <option value={DEVICE_FILTER_VALUES.UNICO}>
                                {intl.formatMessage({ id: 'notificationSending.option.dispositivo.unico' })}
                            </option>
                        </select>
                        <i className={icons.android}></i>
                        <i className={icons.ios}></i>
                        <i className={icons.mobile}></i>
                        {showNumberOfDevicesFound && (
                            <span style={{color: (numberOfDevicesFound>0) ? 'green' : 'red', marginLeft:15}}>
                                <FormattedMessage 
                                    id="notificationSending.text.numeroDispositivos" 
                                    defaultMessage="Número de dispositivos coincidentes: " />
                                {numberOfDevicesFound}
                            </span>
                        )}
                    </div>
                </div >

                
                {showDniField && (
                    <div className='ui form input-group-container'>
                        <div className='input-group'>
                            <label className='label required' htmlFor={FIELDS.INPUT_DNI}>
                                <FormattedMessage 
                                    id="notificationSending.label.dni" 
                                    defaultMessage="DNI:" />                            
                            </label>
                            <input 
                                type="text" 
                                id={FIELDS.INPUT_DNI}
                                name={FIELDS.INPUT_DNI}
                                value={filtro[FIELDS.INPUT_DNI]} 
                                className={(filterValidation[FIELDS.INPUT_DNI])?'':'required-field'}
                                onChange={handleFilterInputChange} 
                                style={{width:150}} 
                                maxLength="9" 
                                size="9"
                            />   
                            <div className='information-text'>
                                {intl.formatMessage({ id: 'notificationSending.text.formatoDNI' })}
                            </div>   
                        </div>  
                   
                    </div>
                )}

                {showFilterCheckbox && (
                    <div className='ui form input-group-container'>
                        <label className='checkbox-label' htmlFor={FIELDS.CHECKBOX_ULTIMA_CONEXION}>
                            <input 
                                type="checkbox"
                                className='checkbox'
                                id={FIELDS.CHECKBOX_ULTIMA_CONEXION}
                                name={FIELDS.CHECKBOX_ULTIMA_CONEXION}
                                onChange={handleFilterInputChange}
                                checked={filtro[FIELDS.CHECKBOX_ULTIMA_CONEXION]}                       
                            />
                            <FormattedMessage 
                                id="notificationSending.label.ultimaConexion" 
                                defaultMessage="Dispositivos con última conexión:" />             
                        </label>
                        
                        {showLastConection && (
                            <div className='input-group'>
                                <label className='label required' htmlFor={FIELDS.INPUT_FECHA_ULTIMA_CONEXION}>
                                    <FormattedMessage 
                                        id="notificationSending.label.fecha" 
                                        defaultMessage="Fecha:" />     
                                </label>
                                <input 
                                    type="date" 
                                    id={FIELDS.INPUT_FECHA_ULTIMA_CONEXION}
                                    name={FIELDS.INPUT_FECHA_ULTIMA_CONEXION}
                                    value={filtro[FIELDS.INPUT_FECHA_ULTIMA_CONEXION]}
                                    className={(filterValidation[FIELDS.INPUT_FECHA_ULTIMA_CONEXION])?'':'required-field'}
                                    onChange={handleFilterInputChange}
                                />
                            </div>
                        )}
                    </div>
                )}
                
                {showFilterCheckbox && (
                    <div className='ui form input-group-container'>
                        <label className='checkbox-label' htmlFor={FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS}>
                            <input 
                                type='checkbox'
                                className='checkbox'
                                id={FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS}
                                name={FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS}
                                onChange={handleFilterInputChange}
                                checked={filtro[FIELDS.CHECKBOX_DISPOSITIVOS_REGISTRADOS]}
                            />
                            <FormattedMessage 
                                id="notificationSending.label.registradosServicio" 
                                defaultMessage="Dispositivos registrados en un servicio:" /> 
                        </label>
                        
                        {showSuscribeService && (  
                            <div className='input-group'>
                                <label className='label required' htmlFor={FIELDS.SELECT_SERVICIOS} >
                                    <FormattedMessage 
                                        id="notificationSending.label.servicio" 
                                        defaultMessage="Servicio:" /> 
                                </label>
                                <select 
                                    id={FIELDS.SELECT_SERVICIOS} 
                                    name={FIELDS.SELECT_SERVICIOS}
                                    value={filtro[FIELDS.SELECT_SERVICIOS]}
                                    className={(filterValidation[FIELDS.SELECT_SERVICIOS])?'':'required-field'}
                                    onChange={handleFilterInputChange}
                                >
                                    <option key="0" value="">
                                        {intl.formatMessage({ id: 'notificationSending.option.servicio.default' })}
                                    </option>
                                    {serviceOptions}
                                </select>
                            </div>
                        )}

                        {showSuscribeService && (       
                            <div className='input-group'>
                                <label className='label required' htmlFor={FIELDS.INPUT_NUMERO_REGISTROS}>
                                    <FormattedMessage 
                                        id="notificationSending.label.numeroRegistros" 
                                        defaultMessage="Nº mínimo registros:"
                                    /> 
                                </label>
                                <input 
                                    type='text'
                                    id={FIELDS.INPUT_NUMERO_REGISTROS}
                                    name={FIELDS.INPUT_NUMERO_REGISTROS}
                                    value={filtro[FIELDS.INPUT_NUMERO_REGISTROS]}
                                    className={(filterValidation[FIELDS.INPUT_NUMERO_REGISTROS])?'':'required-field'}
                                    onChange={handleFilterInputChange}
                                    style={{width: 70}}
                                    placeholder='1 - 99'
                                    min='1' 
                                    max='99'
                                    size='2'
                                />
                            </div> 
                        )}
                    </div>
                )}
                

                {/* Botones filtro dispositivos */}    
                <div style={{ marginTop:20, marginLeft:14, marginBottom: 35}}>
                    <button className="ui red button" onClick={() => onClickFiltrar()}>
                        <FormattedMessage 
                            id="notificationSending.button.filtrar" 
                            defaultMessage="Filtrar" />
                    </button>
                    <button className="ui noBorder button" onClick={() => onClickLimpiarFiltro()}>
                        <FormattedMessage 
                            id="notificationSending.button.limpiar" 
                            defaultMessage="Limpiar filtro" />
                    </button>
                </div>

                {/* Titulo filtro notificacion */}
                <label className="title title-flx required" > 
                    <FormattedMessage 
                        id="notificationSending.title.notificacion" 
                        defaultMessage="Notificación" />
                </label>  

                <div className='ui form' style={{marginTop: 10, marginLeft: 15, display:'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 10}}>
                    {/* Titulo notificacion */}
                    <div className='input-column'>
                        <label htmlFor='titulo' className='required'>
                            <FormattedMessage 
                                id="notificationSending.label.titulo" 
                                defaultMessage="Título:" />
                        </label>
                        <input 
                            type="text"
                            id={FIELDS.INPUT_TITULO}
                            name={FIELDS.INPUT_TITULO}
                            value={notification[FIELDS.INPUT_TITULO]}
                            className={(notificationValidation[FIELDS.INPUT_TITULO])?'':'required-field'}
                            onChange={handleNotificationInputChange} 
                            style={{width: 405}}
                        />
                        <span className={(notification[FIELDS.INPUT_TITULO].length < MAX_LENGTH[FIELDS.INPUT_TITULO]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {notification[FIELDS.INPUT_TITULO].length}/{MAX_LENGTH[FIELDS.INPUT_TITULO]}
                        </span>
                    </div>
                    {/* Descripcion notificacion */}
                    <div className='input-column'>
                        <label htmlFor='descripcion' className='required'>
                            <FormattedMessage 
                                id="notificationSending.label.descripcion" 
                                defaultMessage="Descripción:" />                        
                        </label>
                        <textarea 
                            id={FIELDS.INPUT_DESCRIPCION}
                            name={FIELDS.INPUT_DESCRIPCION}
                            value={notification[FIELDS.INPUT_DESCRIPCION]}
                            className={(notificationValidation[FIELDS.INPUT_DESCRIPCION])?'':'required-field'}
                            onChange={handleNotificationInputChange} 
                            style={{resize: 'none'}}
                            rows="3" 
                            cols="50" 
                        />
                        <span className={(notification[FIELDS.INPUT_DESCRIPCION].length < MAX_LENGTH[FIELDS.INPUT_DESCRIPCION]) ? 'input-length-counter' : 'input-length-counter-red'}>
                            {notification[FIELDS.INPUT_DESCRIPCION].length}/{MAX_LENGTH[FIELDS.INPUT_DESCRIPCION]}
                        </span>
                    </div>
                </div>   

                {/* Botones envio notificacion */}    
                <div style={{ marginTop:10, marginLeft:14, marginBottom: 10}}>
                    <button className="ui red button" onClick={() => onClickEnviar()} disabled={(numberOfDevicesFound > 0) ? false : true}>
                        <FormattedMessage 
                            id="notificationSending.button.enviar" 
                            defaultMessage="Enviar" />
                    </button>
                </div>

            </div>
        </>
    ) 
} 
    
export default NotificationSending;


